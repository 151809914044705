<template>
  <div></div>
</template>

<script>
import { Cookies } from '../../store/config_cookies'
import { Mutation } from '../../store/config_mutations'

export default {
  name: 'CookiePolicy',

  data: function () {
    return {
      cookiesAccepted: false,
    }
  },

  mounted: function () {
    var cookieUser = this.$cookies.get(Cookies.SZOK_COOKIE_ACCEPTED);

    if (cookieUser === null) {
      this.showAlert()
    }
    else {
      this.refreshCookie()
    }
  },

  methods: {
    showAlert() {
      this.$buefy.snackbar.open({
        message: this.t("tools.cookies.policyText"),
        actionText: this.t("tools.cookies.policyAccept"),
        indefinite: true,
        position: 'is-top',
        type: 'is-warning',
        onAction: () => {
          this.$buefy.snackbar.open({
            message: this.t("tools.cookies.policyAccepted"),
            type: 'is-primary', queue: false
          })
          this.refreshCookie()
        }
      })
    },

    refreshCookie() {
      this.$store.commit(Mutation.GENERAL_COOKIES_ACCEPT)
    }
  }
}
</script>

<style scoped lang="scss">
</style>
