export default {
  data: function () {
    return {
      mWorktimeColors: [
        { id: 0, dark: '#68c5d7', light: '#dbeeef', text: '#fff' },
        { id: 1, dark: '#f297ab', light: '#fce4e8', text: '#fff' },
        { id: 2, dark: '#f7bb7f', light: '#feecd7', text: '#fff' },
        { id: 3, dark: '#9d82bb', light: '#f5eef7', text: '#fff' },
        { id: 4, dark: '#7e92ca', light: '#e1e8f9', text: '#fff' },
        { id: 5, dark: '#a9b532', light: '#e9eccb', text: '#fff' },

        { id: 6, dark: '#f297ab', light: '#fce4e8', text: '#fff' },
        { id: 7, dark: '#f297ab', light: '#fce4e8', text: '#fff' },
        { id: 8, dark: '#f297ab', light: '#fce4e8', text: '#fff' },
        { id: 9, dark: '#f297ab', light: '#fce4e8', text: '#fff' },
        { id: 10, dark: '#f297ab', light: '#fce4e8', text: '#fff' },
        { id: 11, dark: '#f297ab', light: '#fce4e8', text: '#fff' },
        { id: 12, dark: '#f297ab', light: '#fce4e8', text: '#fff' },
        { id: 13, dark: '#f297ab', light: '#fce4e8', text: '#fff' },
        { id: 14, dark: '#f297ab', light: '#fce4e8', text: '#fff' },
        { id: 15, dark: '#f297ab', light: '#fce4e8', text: '#fff' },
        { id: 16, dark: '#f297ab', light: '#fce4e8', text: '#fff' },
        { id: 17, dark: '#f297ab', light: '#fce4e8', text: '#fff' },
        { id: 18, dark: '#f297ab', light: '#fce4e8', text: '#fff' },
        { id: 19, dark: '#f297ab', light: '#fce4e8', text: '#fff' },
        { id: 20, dark: '#f297ab', light: '#fce4e8', text: '#fff' },
        { id: 21, dark: '#f297ab', light: '#fce4e8', text: '#fff' },
        { id: 22, dark: '#f297ab', light: '#fce4e8', text: '#fff' },
        { id: 23, dark: '#f297ab', light: '#fce4e8', text: '#fff' },


        { id: 100600, dark: '#68c5d7', light: '#dbeeef', text: '#fff' },
        { id: 100700, dark: '#68c5d7', light: '#dbeeef', text: '#fff' },


        { id: 100301, dark: '#68c5d7', light: '#dbeeef', text: '#fff' },

        { id: 100401, dark: '#68c5d7', light: '#dbeeef', text: '#fff' },
        { id: 100402, dark: '#f297ab', light: '#fce4e8', text: '#fff' },
        { id: 100403, dark: '#f7bb7f', light: '#feecd7', text: '#fff' },
        { id: 100404, dark: '#9d82bb', light: '#f5eef7', text: '#fff' },

        { id: 100501, dark: '#68c5d7', light: '#dbeeef', text: '#fff' },
        { id: 100502, dark: '#f297ab', light: '#fce4e8', text: '#fff' },
        { id: 100503, dark: '#f7bb7f', light: '#feecd7', text: '#fff' },
      ],

      mAppointmentColors: [
        { id: 0, dark: '#028fb3', light: '#22bcef', text: '#fff' },
        { id: 1, dark: '#486e2b', light: '#85b333', text: '#fff' },
        { id: 2, dark: '#512461', light: '#833d90', text: '#fff' },
        { id: 3, dark: '#aa191a', light: '#ea5153', text: '#fff' },
        { id: 4, dark: '#a62b2e', light: '#f08284', text: '#fff' },
        { id: 5, dark: '#cb8205', light: '#f7a71d', text: '#fff' },

        { id: 20, dark: '#068f9c', light: '#3fbbc8', text: '#fff' },
        { id: 21, dark: '#a7173b', light: '#e72063', text: '#fff' },
        { id: 22, dark: '#5d6723', light: '#a9b532', text: '#fff' },
        { id: 23, dark: '#282e63', light: '#475098', text: '#fff' },
        { id: 24, dark: '#068f9c', light: '#3fbbc8', text: '#fff' },
        { id: 25, dark: '#873177', light: '#c384b8', text: '#fff' },
        { id: 26, dark: '#2e7d63', light: '#7db8a5', text: '#fff' },
        { id: 27, dark: '#7c4b23', light: '#ba8c66', text: '#fff' },

        { id: 40, dark: '#028fb3', light: '#22bcef', text: '#fff' },
        { id: 41, dark: '#512461', light: '#833d90', text: '#fff' },

        { id: 50, dark: '#486e2b', light: '#85b333', text: '#fff' },

        { id: 60, dark: '#aa191a', light: '#ea5153', text: '#fff' },
        { id: 61, dark: '#a62b2e', light: '#f08284', text: '#fff' },
        { id: 62, dark: '#cb8205', light: '#f7a71d', text: '#fff' },
        { id: 63, dark: '#068f9c', light: '#3fbbc8', text: '#fff' },
        { id: 64, dark: '#a7173b', light: '#e72063', text: '#fff' },

        { id: 90, dark: '#068f9c', light: '#3fbbc8', text: '#fff' },
        { id: 91, dark: '#a7173b', light: '#e72063', text: '#fff' },
        { id: 92, dark: '#5d6723', light: '#a9b532', text: '#fff' },
        { id: 93, dark: '#282e63', light: '#475098', text: '#fff' },

        { id: 110, dark: '#068f9c', light: '#3fbbc8', text: '#fff' },
        { id: 111, dark: '#a7173b', light: '#e72063', text: '#fff' },
        { id: 112, dark: '#5d6723', light: '#a9b532', text: '#fff' },
        { id: 113, dark: '#282e63', light: '#475098', text: '#fff' },
        { id: 114, dark: '#068f9c', light: '#3fbbc8', text: '#fff' },
        { id: 115, dark: '#873177', light: '#c384b8', text: '#fff' },

        { id: 130, dark: '#068f9c', light: '#3fbbc8', text: '#fff' },




        { id: 140, dark: '#512461', light: '#833d90', text: '#fff' },
        { id: 141, dark: '#028fb3', light: '#22bcef', text: '#fff' },
        { id: 142, dark: '#486e2b', light: '#85b333', text: '#fff' },
      ],
    }
  },
}
