<template>
  <b-field :label="title ? title : 'Szukaj pracownika'" label-position="on-border">
    <b-autocomplete
      :data="subjects"
      v-model="subjectName"
      clearable
      expanded
      field="name"
      style="width: 20rem;"
      open-on-focus
      ref="employeeInput"
      @select="(option) => newEmployeeSelecteed(option)"
    >
      <template slot-scope="props">
        <p
          class="subtitle is-6 mb-0"
          :class="{'has-text-grey':props.option.isOtherDomain}"
          style="line-height: 1borekrem;"
        >
          {{ props.option.lastName }}
          {{ props.option.firstName }}
        </p>
        <p
          v-if="props.option.isOtherDomain"
          class="has-text-danger is-size-7"
        >Uwaga! Inna domena/obszar</p>
      </template>
    </b-autocomplete>
  </b-field>
</template>

<script>
import CommonMixins from '../../mixins/commons'

export default {
  name: 'EmployeeSelector',
  mixins: [CommonMixins],

  props: {
    title: { type: String, required: false },
  },

  data: function () {
    return {
      subjectName: ''
    }
  },

  computed: {
    employees() {
      if (this.$store.state.employee.all !== null) {
        return this.$store.state.employee.all;
      } else return [];
    },

    subjects() {
      let array = this.employees
      if (array === null) array = []

      return array.filter((option) => {
        if (option.name) {
          return option.name
            .toString()
            .toLowerCase()
            .indexOf(this.subjectName.toLowerCase()) >= 0
        }
        else return []
      })
    },
  },

  methods: {
    newEmployeeSelecteed(val) {
      this.$emit('selected', val)
      this.subjectName = ''
    }
  }
}
</script>

<style scoped lang="scss">
</style>
